import React from 'react'
import Layout from '../components/layout'
import SEO from '../components/seo'
import PageWrap from '../components/page-wrap'

const Page = props => {
  return (
    <Layout>
      <SEO title="Contact" />
      <PageWrap>
        <h1>Scratch Pad</h1>
      </PageWrap>
    </Layout>
  )
}

export default Page
